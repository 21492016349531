import React from 'react';

import './styles.scss';

function Loading1({ text = 'Samantha almost ready for you'}) {
  return (
    <div className="loading1">
      <p className="loading1__text">{ text } </p>
      <div className="loading1__line"></div>
    </div>
  );
}

export default Loading1;