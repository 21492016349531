import React, { Fragment, useEffect } from 'react';

import { useComponentSelect } from './hooks';

import {
  Loading1,
  AnimationHandler,
  FullPageText,
} from './Components';

const text = `
O Sr Leopardo está chapado a 8 dias.
`;

const L2 = (props) => <FullPageText color="#000" background="#DBBDB3" text={text} />

function App() {
  const { next, Component, index } = useComponentSelect(Loading1, L2);

  useEffect(() => {
    const t = setTimeout(next, 5000);
    return () => clearTimeout(t);
  }, []); //eslint-disable-line

  return (
    <Fragment>
      <AnimationHandler duration={1}>
        <Component key={`loading-${index}`} text="Verificando quantos dias o Léo tá chapado"/>
      </AnimationHandler>
    </Fragment>
  );
}

export default App;
