import { useState } from 'react';

export function useComponentSelect(...components) {
  const [currentElementIndex, setCurrentElementIndex] = useState(0);

  return {
    index: currentElementIndex,
    set: setCurrentElementIndex,
    next: () => setCurrentElementIndex((currentElementIndex + 1) % components.length),
    previous: () => setCurrentElementIndex((currentElementIndex - 1 + components.length) % components.length),
    Component: components[currentElementIndex],
  }
}