import React, { useState } from 'react';

import './styles.scss';

function AnimationHandler ({ children, duration = 2 }) {
  const [ previousComponent, setPreviousComponent ] = useState(children);

  const animClass = previousComponent !== children ? '__play' : '';

  const animConfig = {
    animationDuration: `${duration}s`,
  };

  return (
    <div className="animation-handler">
      <div 
        className={`animation-handler__in${animClass}`} 
        onAnimationEnd={() => setPreviousComponent(children)}
        style={animConfig}
      >
        { children }
      </div>
      <div 
        className={`animation-handler__out${animClass}`}
        style={animConfig}
      >
        { previousComponent }
      </div>
    </div>
  )
}

export default AnimationHandler;